//Установка правильного размера окна

// function resizemain() {
//  jQuery(".main").css({"min-height":jQuery(window).innerHeight()});
// }
// resizemain();
// jQuery(window).resize(function() {
//  resizemain();
// });

// $(document).ready( function(){
  //Клик по бургер-меню

  // document.getElementsByClassName("burger-button")[0].addEventListener("click", function() {
  //   this.classList.toggle("active");
  //   document.getElementsByTagName("nav")[0].classList.toggle("active");
  //   document.getElementsByTagName("body")[0].classList.toggle("ovh");
  // });
// })
